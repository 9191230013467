<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-range-picker v-model="form.date" />
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button @click="download" type="primary">导出</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :columns="[
          {
            key: 'deptName',
            title: '部门',
            scopedSlots: { customRender: 'deptName' },
          },
          ...titleList,
        ]"
        :dataSource="list"
        :loading="loading"
        :pagination="false"
      >
        <router-link
          slot="deptName"
          slot-scope="text, record"
          :to="setPath(record)"
          >{{ record.deptName }}</router-link
        >
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchApplyList } from "@/api/oa/material";
import { downloadByHtml } from "@/utils/xlsx";
import moment from "moment";
export default {
  name: "materialList",
  mixins: [watermark],

  data() {
    return {
      form: {},
      loading: false,
      titleList: [],
      list: [],
    };
  },

  mounted() {
    // 本月第一天和本月最后一天
    const year = moment().year();
    const month = moment().month();

    this.form = {
      date: [
        moment([year, month, 1]),
        moment([year, month + 1, 1]).subtract(1, "days"),
      ],
    };

    this.getList();
  },

  methods: {
    getList() {
      const { date } = this.form;

      let startTime, endTime;
      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
      }

      this.loading = true;
      fetchApplyList({
        startTime,
        endTime,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.titleList) && Array.isArray(res.dataList)) {
            this.titleList = res.titleList.map((item) => ({
              title: item,
              children: [
                {
                  title: "数量",
                  dataIndex: item + "数量",
                  align: "center",
                },
                {
                  title: "金额(元)",
                  dataIndex: item + "金额",
                  align: "center",
                },
              ],
            }));

            this.list = res.dataList
              .filter((item) => Array.isArray(item.dataList))
              .map((item) => {
                const obj = {};
                for (let i = 0; i < res.titleList.length; i++) {
                  const title = res.titleList[i];
                  const values = item.dataList[i];
                  obj[title + "数量"] = values.quantity;
                  obj[title + "金额"] = values.totalPrice;
                }

                return {
                  deptName: item.deptName,
                  deptId: item.deptId,
                  ...obj,
                };
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    query() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.getList();
    },

    download() {
      downloadByHtml(document.getElementsByTagName("table")[0], "物料申领台账");
    },

    setPath(record) {
      let url = this.$route.path + "/detail?deptId=" + record.deptId;

      const { date } = this.form;
      let startTime, endTime;
      if (Array.isArray(date) && date.length === 2) {
        startTime = date[0].format("YYYY-MM-DD");
        endTime = date[1].format("YYYY-MM-DD");
        url = `${url}&startTime=${startTime}&endTime=${endTime}`;
      }
      return url;
    },
  },
};
</script>

